const AppSettings = {
  ApplicationName: "OmaDot dashboard",
};

export const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false, // Ensure 24-hour format
};

export default AppSettings;
