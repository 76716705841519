import React, { useState, useEffect } from "react";
import Input from "../../components/Basic/Input";
const SingleLanguage = ({
  editableList,
  isEditable = true,
  siteDefaultLanguageId = "en-GB",
  siteLanguages,
  onSave,
}) => {
  const [isRunningTask, setIsRunningTask] = useState(false);
  const [list, setList] = useState(null);
  const [listBackup, setListBackup] = useState(null);
  //   const [siteDefaultLanguageId, setSiteDefaultLanguageId] = useState(null);

  useEffect(() => {
    const mergedArray = (siteLanguages || []).map((siteLanguages) => {
      const match = (editableList || []).find(
        (itemWithValue) => itemWithValue.languageId === siteLanguages.languageId
      );

      return {
        languageId: siteLanguages.languageId,
        value: match ? match.value : "",
        notFound: !match,
      };
    });

    // Update the list state with the merged array
    setList(mergedArray);
    setListBackup(mergedArray);
  }, []); // Empty dependency array to run once on mount

  return (
    <>
      {list && list.length > 0 ? (
        <>
          <div className="container">
            {list.map((item, index) => {
              return (
                <>
                  <div className="form-group d-flex">
                    <label className="col-sm-2 col-form-label font-weight-bold">
                      {item ? item.languageId : ""}
                    </label>
                    <div className="col-sm-10">
                      <Input
                        placeholder={item.value}
                        value={item ? item.value : ""}
                        onChange={(e) => {
                          // Create a new array with the updated value for the changed item
                          const updatedList = list.map((el, idx) =>
                            idx === index
                              ? { ...el, value: e.target.value }
                              : el
                          );

                          // Update the list state with the modified value
                          setList(updatedList);
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            })}
            <div>
              <button
                className="btn btn-xsm bg-gradient-dark m-0 ms-2 mb-2"
                disabled={isRunningTask}
                onClick={(e) => {
                  setIsRunningTask(true);
                  onSave(list);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </>
      ) : (
        <>Empty. Please add first languages to your site.</>
      )}
    </>
  );
};
export default SingleLanguage;
