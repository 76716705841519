import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Input from "../../components/Basic/Input";
import Toggle from "../../components/Basic/Toggle";
import Accordion from "react-bootstrap/Accordion";
import { Get, Put } from "../../services/httpService";
import { GenerateUrl } from "../../services/apiService";
import SingleLanguage from "../../editors/Languages/SingleLanguage";
import Modal from "../../components/Basic/Modal";
import ParentsList from "../../components/ParentsList/ParentsList";

const SiteSettings = ({
  projectLanguages,
  projectParents,
  projectPagesBasic,
}) => {
  const [tab, setTab] = useState("general");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [siteConfig, setSiteConfig] = useState(null);
  const [siteFooter, setSiteFooter] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [addPageToFooterId, setAddPageToFooterId] = useState(null);
  const [siteDefaultLanguageId, setSiteDefaultLanguageId] = useState("en-GB");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoadingPage) {
          setIsLoadingPage(false);
          const config = await Get(GenerateUrl(`project/config`));
          const footer = await Get(GenerateUrl(`component/type/Footer`));
          setSiteConfig(config);
          setSiteFooter(footer);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingPage(false);
      }
    };
    fetchData();
  }, []);

  const getItemByDefaultLanguageId = (item) => {
    return item && item.find((x) => x.languageId == siteDefaultLanguageId)
      ? item.find((x) => x.languageId == siteDefaultLanguageId)
      : null;
  };
  async function UpdateSite() {}

  async function onAddPageSelected(page) {
    if (page && addPageToFooterId) {
      const foundFooter = siteFooter
        ? siteFooter.find((x) => x.keyId == addPageToFooterId)
        : null;
      if (foundFooter) {
      }
      const updatedData = siteFooter.map((f) => {
        if (f.keyId === addPageToFooterId) {
          const updatedItems =
            foundFooter.pageIds && foundFooter.pageIds.length > 0
              ? [...foundFooter.pageIds]
              : [];
          updatedItems.push({ targetId: page.levelId, order: 0 });

          // Return a new object with the updated items
          return {
            ...f,
            pageIds: updatedItems,
          };
        }

        // Return the group unchanged if it's not the one we're modifying
        return f;
      });
      const result = await Put(GenerateUrl(`component/list`), updatedData);
      if (result) {
        // Update the state with the modified data
        setSiteFooter(updatedData);
        setAddPageToFooterId(null);
      }
    }
  }
  return (
    <>
      {editItem && (
        <Modal show={true} fullscreen={false} onHide={(e) => setEditItem(null)}>
          <div>
            <SingleLanguage
              siteLanguages={projectLanguages}
              editableList={editItem.translations}
              onSave={(e) => {}}
            />
          </div>
        </Modal>
      )}
      {addPageToFooterId && (
        <Modal
          title="Parent selection"
          show={true}
          onHide={(e) => setAddPageToFooterId(null)}
          fullscreen={false}
          hasCloseButton={true}
        >
          <ParentsList
            parents={projectParents}
            onSelected={onAddPageSelected}
            selectOnlyParentWithChild={false}
            projectPagesBasic={projectPagesBasic}
          />
        </Modal>
      )}
      <Nav
        className="justify-content-center"
        activeKey
        variant="pills"
        defaultActiveKey={tab}
      >
        <Nav.Item>
          <Nav.Link
            eventKey="general"
            onClick={(e) => {
              setTab("general");
            }}
            className="me-3"
          >
            General Info
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="seo"
            onClick={(e) => {
              setTab("seo");
            }}
            className="me-3"
          >
            SEO2
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="contact-info"
            onClick={() => {
              setTab("contact-info");
            }}
            className="me-3"
          >
            Contact Information
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="footer"
            onClick={() => {
              setTab("footer");
            }}
            className="me-3"
          >
            Footer
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            eventKey="all"
            onClick={() => {
              setTab("all");
            }}
            className="me-3"
          >
            All
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <div className="col-12 border-r-3 p-3 mt-3 mb-6">
        {(tab == "general" || tab == "all") && (
          <>
            <div class="row mt-4">
              <div class="col-lg-8 mb-3">
                <div class="card">
                  <div class="card-header p-3 pt-2">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                          <i class="material-icons opacity-10">
                            <i class="bi bi-gear app-icon"></i>
                          </i>
                        </div>
                        <h6 class="mb-0">General info</h6>
                      </div>
                      <div class="col-6 text-end">
                        <button
                          class="btn btn-sm btn-primary m-sm-0 me-sm-3"
                          disabled="@isDisabled"
                          onClick={(e) => UpdateSite()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-2">
                    <div className="form-group">
                      <Input
                        placeholder="Name"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Tags"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Email"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        placeholder="Status"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <label className="font-weight-bold me-3">
                      Auto Comment Approvel
                      <Toggle
                        className="ms-3"
                        checked="Yes"
                        unChecked="No"
                        defaultChecked={true}
                        onChange={(e) => {
                          // setShowHideRichTextEditor(!showHideRichTextEditor);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card mb-3">
                  <div class="card-body p-3 pt-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="m-0 fw-bold">Logo Image</p>
                      @if (!string.IsNullOrEmpty(projectConfig.LogoImageId))
                      {
                        <a class="btn btn-link text-danger text-gradient aclickable ps-0 pe-0 mb-0"></a>
                      }
                    </div>
                    <div class="form-group d-flex m-0 justify-content-center">
                      @if (string.IsNullOrEmpty(projectConfig.LogoImageId))
                      {}
                      else
                      {
                        // <img src="@ImagesGenerator.GetThumbnail(projectConfig.LogoImageId, projectId)" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg img-sm">
                      }
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body p-3 pt-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="m-0 fw-bold">Favicon Image</p>
                      @if (!string.IsNullOrEmpty(projectConfig.FaviconImageId))
                      {}
                    </div>
                    <div class="form-group d-flex m-0 justify-content-center">
                      @if (string.IsNullOrEmpty(projectConfig.FaviconImageId))
                      {
                        // <ImageUploader ProjectId="@projectId" OnImageSelected="UploadedFaviconImage" />
                      }
                      else
                      {}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {(tab == "seo" || tab == "all") && (
          <>
            <div class="row mt-4">
              <div class="col-lg-8 mb-3">
                <div class="card">
                  <div class="card-header p-3 pt-2">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                          <i class="material-icons opacity-10">
                            <i class="bi bi-gear app-icon"></i>
                          </i>
                        </div>
                        <h6 class="mb-0">SEO</h6>
                      </div>
                      <div class="col-6 text-end">
                        <button
                          class="btn btn-sm btn-primary m-sm-0 me-sm-3"
                          disabled="@isDisabled"
                          onClick={(e) => UpdateSite()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-2">
                    <div className="form-group">
                      <Input
                        placeholder="Title"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Description"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card mb-3">
                  <div class="card-body p-3 pt-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="m-0 fw-bold">Main Image</p>
                      @if (!string.IsNullOrEmpty(projectConfig.LogoImageId))
                      {
                        <a class="btn btn-link text-danger text-gradient aclickable ps-0 pe-0 mb-0"></a>
                      }
                    </div>
                    <div class="form-group d-flex m-0 justify-content-center">
                      @if (string.IsNullOrEmpty(projectConfig.LogoImageId))
                      {}
                      else
                      {
                        // <img src="@ImagesGenerator.GetThumbnail(projectConfig.LogoImageId, projectId)" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg img-sm">
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {(tab == "contact-info" || tab == "all") && (
          <>
            <div class="row mt-4">
              <div class="col-lg-8 mb-3">
                <div class="card">
                  <div class="card-header p-3 pt-2">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                          <i class="material-icons opacity-10">
                            <i class="bi bi-gear app-icon"></i>
                          </i>
                        </div>
                        <h6 class="mb-0">General info</h6>
                      </div>
                      <div class="col-6 text-end">
                        <button
                          class="btn btn-sm btn-primary m-sm-0 me-sm-3"
                          disabled="@isDisabled"
                          onClick={(e) => UpdateSite()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-2">
                    <div className="form-group">
                      <Input
                        placeholder="Copyright text"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Footer text"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Phone number"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Input
                        placeholder="Opening Time"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Address"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Location iframe"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <Input
                        placeholder="Support email"
                        // value={pageValues ? pageValues.description : ""}
                        onChange={(e) => {
                          // pageValues.description = e.target.value;
                          // SetPage(pageValues);
                          // const newp = GetPage(levelId, topLevelId, languageId);
                          // setPageValues(newp);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {(tab == "footer" || tab == "all") && (
          <>
            <div class="row mt-4">
              <div class="col-lg-8 mb-3">
                <div class="card">
                  <div class="card-header p-3 pt-2">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                          <i class="material-icons opacity-10">
                            <i class="bi bi-gear app-icon"></i>
                          </i>
                        </div>
                        <h6 class="mb-0">Footer</h6>
                      </div>
                      <div class="col-6 text-end">
                        <button
                          class="btn btn-sm btn-primary m-sm-0 me-sm-3"
                          disabled="@isDisabled"
                          onClick={(e) => UpdateSite()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-2">
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      {siteFooter &&
                        projectParents &&
                        projectParents.length > 0 &&
                        siteFooter.map((footer) => {
                          const nameItem = getItemByDefaultLanguageId(
                            footer.translations
                          );
                          return (
                            <>
                              <Accordion.Item eventKey={footer.keyId}>
                                <Accordion.Header>
                                  <div class="row">
                                    <div class="col-8">
                                      <div class="btn-group">
                                        <span className="text-nowrap me-3">
                                          {nameItem ? nameItem.value : "-"}
                                        </span>
                                        <div
                                          class="ms-1"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }}
                                        >
                                          <a
                                            class="cursor-pointer"
                                            onClick={(e) => {
                                              setEditItem(footer);
                                            }}
                                          >
                                            <a class="btn btn-link text-dark ps-0 pe-0">
                                              <i class="bi bi-pencil-square font-xl app-icon"></i>
                                            </a>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-4 text-end">
                                      <div>
                                        <div>
                                          <a
                                            class="cursor-pointer"
                                            onclick="@(e =>  DeleteFooterComponent(item.KeyId))"
                                          >
                                            <a class="btn btn-link text-dark ps-0 pe-0">
                                              <i class="bi bi-trash3-fill font-xl app-icon"></i>
                                            </a>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <button
                                    class="accordion-button border-bottom font-weight-bold collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    {foot.translations &&
                                    foot.translations.length > 0 ? (
                                      <>
                                        {
                                          foot.translations.find(
                                            (x) =>
                                              x.languageId ==
                                              siteDefaultLanguage
                                          )?.value
                                        }
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                    <i
                                      class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                                      aria-hidden="true"
                                    ></i>
                                  </button> */}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div class="mb-2">
                                    <a
                                      class="cursor-pointer ms-2 me-2"
                                      onclick="@(e=> {
        currentComponentId = item.KeyId;
        //showTextFooterEditor = true;
        OnLanguageClick(item);
    StateHasChanged();

    })"
                                    >
                                      <i class="bi bi-plus-square me-1"></i>
                                      <span>Text</span>
                                    </a>

                                    <a
                                      class="cursor-pointer"
                                      onClick={(e) => {
                                        setAddPageToFooterId(footer.keyId);
                                      }}
                                      onclick="@(e=> {
        currentComponentId = item.KeyId;
        pageCategoryMethods.OpenOnSelectLocationDialog(new Categories());
        })"
                                    >
                                      <i class="bi bi-plus-square me-1"></i>
                                      <span>Page</span>
                                    </a>
                                  </div>
                                  {footer.pageIds &&
                                  footer.pageIds.length > 0 ? (
                                    <>
                                      <ul class="list-group">
                                        {footer.pageIds.map((page) => {
                                          const pageInfo =
                                            projectParents &&
                                            projectParents.length > 0
                                              ? projectParents.find(
                                                  (x) =>
                                                    x.levelId == page.targetId
                                                )
                                              : null;
                                          const pageByDefaultLanguage = pageInfo
                                            ? getItemByDefaultLanguageId([
                                                pageInfo,
                                              ])
                                            : null;
                                          return (
                                            <>
                                              {pageInfo ? (
                                                <>
                                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    {pageInfo
                                                      ? pageInfo.name
                                                      : "-"}
                                                    <span
                                                      class="badge badge-primary badge-pill cursor-pointer"
                                                      onClick={async (e) => {
                                                        const updatedData =
                                                          siteFooter.map(
                                                            (f) => {
                                                              if (
                                                                f.keyId ===
                                                                footer.keyId
                                                              ) {
                                                                // If this is the group we want to modify, create a copy of the items array
                                                                const updatedItems =
                                                                  f.pageIds.filter(
                                                                    (p) =>
                                                                      p.targetId !==
                                                                      page.targetId
                                                                  );

                                                                // Return a new object with the updated items
                                                                return {
                                                                  ...f,
                                                                  pageIds:
                                                                    updatedItems,
                                                                };
                                                              }

                                                              // Return the group unchanged if it's not the one we're modifying
                                                              return f;
                                                            }
                                                          );
                                                        const result =
                                                          await Put(
                                                            GenerateUrl(
                                                              `component/list`
                                                            ),
                                                            updatedData
                                                          );
                                                        if (result) {
                                                          // Update the state with the modified data
                                                          setSiteFooter(
                                                            updatedData
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <i class="bi bi-trash3-fill font-xl app-icon"></i>
                                                    </span>
                                                  </li>
                                                </>
                                              ) : (
                                                <>Page not found.</>
                                              )}
                                            </>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  ) : (
                                    <>Empty</>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </>
                          );
                        })}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default SiteSettings;
