import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { generateHeaders } from "../../services/httpService";
import { getApiEndpoint } from "../../services/apiService";

const FileUploader = ({ onRemove, onSuccessUpload, pageId, tmpfileId }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const generateileHeader = () => {
    const headers = generateHeaders(false);
    headers["FileAction"] = 0;
    headers["PageId"] = pageId;
    return headers;
  };
  const uploadProps = {
    accept: "application/pdf",
    name: "uploadFiles",
    action: `${getApiEndpoint()}file/save`,
    listType: "picture",
    maxCount: 1,
    onRemove: onRemove,
    headers: generateileHeader(),
    disabled: isDisabled,
    onChange(info) {
      setIsDisabled(true);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(`${info.file.name} file uploaded successfully`);
        if (onSuccessUpload && info.file.response && info.file.response.id) {
          console.log(`${info.file.response.id} file id`);
          onSuccessUpload(info.file.response.id, tmpfileId);
        }
        setIsDisabled(false);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
        setIsDisabled(false);
      }
    },
    multiple: false,
  };
  return (
    <>
      <Upload {...uploadProps}>
        <Button disabled={isDisabled} icon={<UploadOutlined />}>
          Upload
        </Button>
      </Upload>
    </>
  );
};
export default FileUploader;
