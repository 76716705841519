import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import {
  AddMainPage,
  ChangeMainPageOrder,
  DeleteMainPage,
  GetAllMainPage,
} from "../../services/apiService";
import ParentsList from "../../components/ParentsList/ParentsList";
import Input from "../../components/Basic/Input";

const MainPage = ({ projectParents, projectPagesBasic, isLoading }) => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isActionRunning, setIsActionRunning] = useState(false);
  const [viewTab, setViewTab] = useState("Mainpage");
  const [list, setList] = useState(null);
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoadingPage) {
          setIsLoadingPage(false);
          const response = await GetAllMainPage(viewTab);
          setList(response);
          setLists([{ type: viewTab, data: response }]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingPage(false);
      }
    };
    fetchData();
  }, []);

  async function onTabChange(type) {
    setIsLoadingPage(true);
    setViewTab(type);
    if (lists.some((x) => x.type == type)) {
      setList([...lists.find((x) => x.type == type).data]);
    } else {
      const response = await GetAllMainPage(type);
      if (response && response.length > 0) {
        setList([...response]);
      } else {
        setList([]);
      }
      if (!lists.some((x) => x.type == type)) {
        const tmpLists = lists;
        tmpLists.push({ type: type, data: response });
        setLists([...tmpLists]);
      }
    }

    setIsLoadingPage(false);
  }

  async function onLocationChange(newParent) {
    debugger;
    if (viewTab) {
      setIsLoadingPage(true);
      const highestOrder =
        list && list.length > 0
          ? Math.max(...list.map((item) => item.order))
          : -1;

      const order = highestOrder + 1;
      const resultId = await AddMainPage({
        settingType: viewTab,
        PageId: newParent.levelId,
        Purpose: viewTab,
        Order: order,
      });
      if (resultId) {
        const newItem = {
          name: newParent.name,
          mainPageSettingsId: resultId,
          createDate: new Date().toISOString(),
          purpose: viewTab,
          pageId: newParent.levelId,
          order: order,
        }; // Create a new item with a random order value
        const updatedData = [...list, newItem];
        setList(updatedData); // Add the new item to the array and update the state

        const updatedList = list.map((item) =>
          item.type === viewTab ? { ...item, data: updatedData } : item
        ); // Update the specific object in the list

        setLists(updatedList); // Update the state with the new list
      }
      setIsLoadingPage(false);
    }
  }

  const handleOrderChange = async (id, newOrder) => {
    // Ignore changes if newOrder is null or empty
    if (newOrder === "" || newOrder === null) {
      newOrder = 0;
    }

    // // Update the specific item's order
    const updatedList = list.map((item) =>
      item.mainPageSettingsId === id
        ? { ...item, order: parseInt(newOrder, 10) }
        : item
    );
    setList(updatedList);

    setIsActionRunning(true);
    const result = await ChangeMainPageOrder(id, newOrder);

    if (result) {
      // Sort the updated list based on the new order values
      const sortedList = updatedList.sort((a, b) => a.order - b.order);
      // // Reassign orders to ensure uniqueness and proper sequence
      const reorderedList = sortedList.map((item, index) => ({
        ...item,
        order: item.order,
      }));
      setList(reorderedList);

      const updatedList = list.map((item) =>
        item.type === viewTab ? { ...item, data: reorderedList } : item
      ); // Update the specific object in the list

      setLists(updatedList); // Update the state with the new list
    }
    setIsActionRunning(false);
  };

  async function Delete(id) {
    setIsActionRunning(true);

    const result = await DeleteMainPage(id);

    if (result) {
      const tmpList = list.filter((x) => x.mainPageSettingsId != id);
      setList([...tmpList]);

      const updatedList = list.map((item) =>
        item.type === viewTab ? { ...item, data: tmpList } : item
      ); // Update the specific object in the list

      setLists(updatedList); // Update the state with the new list
    }

    setIsActionRunning(false);
  }

  return (
    <>
      <div class="col-12 d-flex align-items-center">
        <button
          onClick={async (e) => await onTabChange("Mainpage")}
          disabled={isActionRunning}
          className={`btn btn-sm me-3 ${
            viewTab == "Mainpage" ? "bg-gradient-dark" : "btn-secondary"
          }`}
        >
          Main page
        </button>

        <button
          onClick={async (e) => await onTabChange("Carousel")}
          disabled={isActionRunning}
          className={`btn btn-sm me-3 ${
            viewTab == "Carousel" ? "bg-gradient-dark" : "btn-secondary"
          }`}
        >
          Carousel
        </button>

        <button
          onClick={async (e) => await onTabChange("Carousel3d")}
          disabled={isActionRunning}
          className={`btn btn-sm me-3 ${
            viewTab == "Carousel3d" ? "bg-gradient-dark" : "btn-secondary"
          }`}
        >
          Carousel 3D
        </button>
      </div>
      <div class="row col-md-12 m-0">
        <div class="col-md-6">
          {isLoadingPage ? (
            <>loading..</>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {list && list.length > 0 ? (
                    <>
                      {list.map((item) => {
                        return (
                          <tr>
                            <td>
                              <Input
                                placeholder="Order"
                                value={item.order}
                                onChange={(e) =>
                                  handleOrderChange(
                                    item.mainPageSettingsId,
                                    e.target.value
                                  )
                                }
                                readOnly={isActionRunning}
                              />
                            </td>
                            <td>{item.name}</td>
                            <td>
                              {" "}
                              <button
                                type="button"
                                rel="tooltip"
                                class="btn btn-danger btn-icon btn-sm"
                                disabled={isActionRunning}
                                onClick={async (e) =>
                                  await Delete(item.mainPageSettingsId)
                                }
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>Nothing found</>
                  )}
                </tbody>
              </Table>
            </>
          )}
        </div>
        <div class="col-md-6">
          {!isLoading && (
            <ParentsList
              parents={
                projectParents && projectParents.length > 0
                  ? projectParents
                  : []
              }
              onSelected={onLocationChange}
              projectPagesBasic={projectPagesBasic}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MainPage;
