// src/telemetry.js
import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
function GenerateUrl(url) {
  return `${apiEndpoint}${url}`;
}
const customTelemetryEndpoint = GenerateUrl("telemetry");

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "YOUR_INSTRUMENTATION_KEY",
    disableTelemetry: false,
    disableAjaxTracking: true,
    disableExceptionTracking: true,
  },
});

appInsights.loadAppInsights();

// Custom method to send telemetry to specific endpoints
const sendTelemetryToCustomEndpoint = (telemetryItem, endpoint) => {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", endpoint, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(JSON.stringify(telemetryItem));
};

// Override trackPageView
appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.baseType === "PageviewData") {
    sendTelemetryToCustomEndpoint(
      envelope,
      `${customTelemetryEndpoint}/pageview`
    );
    return false; // Prevent sending to default endpoint
  } else {
    if (
      envelope.baseType == "RemoteDependencyData" ||
      envelope.baseType == "MessageData" ||
      envelope.baseType == "PageviewPerformanceData"
    ) {
      sendTelemetryToCustomEndpoint(
        envelope,
        `${customTelemetryEndpoint}/request`
      );
    } else {
      debugger;
    }
    return false; // Prevent sending to default endpoint
  }
});

// Override trackEvent
appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.data.baseType === "EventData") {
    sendTelemetryToCustomEndpoint(envelope, `${customTelemetryEndpoint}/event`);
    return false; // Prevent sending to default endpoint
  }
});

// Override trackException
appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.data.baseType === "ExceptionData") {
    sendTelemetryToCustomEndpoint(
      envelope,
      `${customTelemetryEndpoint}/exception`
    );
    return false; // Prevent sending to default endpoint
  }
});

// Function to track requests
export const trackRequest = (name, url, duration, success) => {
  const requestTelemetry = {
    baseType: "RequestData",
    baseData: {
      name,
      url,
      duration,
      success,
      responseCode: success ? 200 : 500,
    },
  };
  sendTelemetryToCustomEndpoint(
    requestTelemetry,
    `${customTelemetryEndpoint}/request`
  );
};

export const trackPageView = (name) => {
  appInsights.trackPageView({ name });
};

export const trackEvent = (name, properties) => {
  appInsights.trackEvent({ name, properties });
};

export const trackException = (error) => {
  const exceptionTelemetry = {
    baseType: "ExceptionData",
    baseData: {
      exceptions: [
        {
          typeName: error.name,
          message: error.message,
          hasFullStack: true,
          stack: error.stack,
        },
      ],
    },
  };
  appInsights.trackException(exceptionTelemetry);
};
