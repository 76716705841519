const Dashboard = (props) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col-lg-3 col-md-6 col-sm-6">
          {/* @if (isStorageLoading)
        {
            <Loading />
        }
        else
        {
            <StorageUsage Value="@storageValue" LastUpdated="@storageUsage?.LastUpdated" />
        } */}
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mt-sm-0 mt-4">
          <div className="card  mb-2">
            {/* @if (appSubscription != null && subLevels != null && topLevels != null)
            {
                <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl mt-n4 position-absolute">
                        <i className="material-icons opacity-10"><i className="app-icon bi bi-files"></i></i>
                    </div>
                    <div className="text-end pt-1">
                        <a href="/page" className="text-sm mb-0 text-capitalize">Pages</a>
                        <h4 className="mb-0">@subLevels?.Count() / @appSubscription?.PageLimit</h4>
                    </div>
                </div>
                <hr className="dark horizontal my-0">
                <div className="card-footer p-3">
                    <p className="mb-0">
                        Titles
                        <span className="text-success text-sm font-weight-bolder"> @topLevels.Count() / @appSubscription.TitleLimit</span>
                    </p>
                </div>
            } */}
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <div className="card  mb-2">
            <div className="card-header p-3 pt-2 bg-transparent">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                <i className="material-icons opacity-10">
                  <i className="app-icon bi bi-file-ruled-fill"></i>
                </i>
              </div>
              <div className="text-end pt-1">
                <a href="/template" className="text-sm mb-0 text-capitalize ">
                  Template
                </a>
                <h4 className="mb-0 ">Clean</h4>
              </div>
            </div>
            <hr className="horizontal my-0 dark" />
            <div className="card-footer p-3">
              <p className="mb-0 ">
                <span className="text-success text-sm font-weight-bolder">
                  {" "}
                  Change template{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <div className="card ">
            {/* @if (appSubscription != null && userSubscription != null)
            {
                <div className="card-header p-3 pt-2 bg-transparent">
                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                        <i className="material-icons opacity-10"><i className="app-icon bi bi-heart-fill 28"></i></i>
                    </div>
                    <div className="text-end pt-1">
                        <a href="/subscriptions" className="text-sm mb-0 text-capitalize ">Plan</a>
                        <h4 className="mb-0 ">
                            @appSubscription.Name
                            <a className="btn btn-sm btn-primary" href="/update-subscription">Update</a>
                        </h4>
                    </div>
                </div>
                <hr className="horizontal my-0 dark">
                <div className="card-footer p-3">
                    <p className="mb-0 ">Ends <span className="text-success text-sm font-weight-bolder"> @userSubscription?.EndDate.ToString("dd.MM.yyyy HH:mm:ss") </span></p>
                </div>
            } */}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6">
          {/* @if (storageStatistics != null && appSubscription != null)
        {
            <div className="card">
                <div className="card-body p-0">
                    <SfAccumulationChart EnableAnimation="false" EnableSmartLabels="true" Theme="@Theme.Bootstrap5" Title="@($"Storage Statistics. Max usage: {StorageTool.GetSize(appSubscription.StorageBytesLimit)}")">
                        <AccumulationChartLegendSettings Visible="false"></AccumulationChartLegendSettings>
                        <AccumulationChartTooltipSettings Enable="true"></AccumulationChartTooltipSettings>
                        <AccumulationChartSeriesCollection>
                            <AccumulationChartSeries DataSource="@storageStatistics" XName="Type" YName="Value" Palettes="@(new[]{ColorTool.GetColor(storageStatistics.First().DPercentage),ColorTool.DefaultSecondaryColor})"
                                                     Radius="70%" InnerRadius="40%" Name="Project" Explode="true" ExplodeOffset="10%" ExplodeIndex="3">
                                <AccumulationDataLabelSettings Visible="true" Name="Percentage">
                                    <AccumulationChartDataLabelFont FontWeight="600" Color="white"></AccumulationChartDataLabelFont>
                                </AccumulationDataLabelSettings>
                            </AccumulationChartSeries>
                        </AccumulationChartSeriesCollection>
                    </SfAccumulationChart>
                </div>
            </div>
        } */}
        </div>
        <div className="col-md-6">
          <div className="card">
            {/* @if (pageStatistics != null && appSubscription != null)
            {
                <div className="card-body p-0">
                    <SfAccumulationChart EnableAnimation="false" EnableSmartLabels="true" Theme="@Theme.Bootstrap5" Title="@($"Page usage. Max: {appSubscription.PageLimit} pages")">
                        <AccumulationChartLegendSettings Visible="false"></AccumulationChartLegendSettings>
                        <AccumulationChartTooltipSettings Enable="true"></AccumulationChartTooltipSettings>
                        <AccumulationChartSeriesCollection>
                            <AccumulationChartSeries DataSource="@pageStatistics" XName="Type" YName="Value" Palettes="@(new[]{ColorTool.GetColor(pageStatistics.First().DPercentage),ColorTool.DefaultSecondaryColor})"
                                                     Radius="70%" InnerRadius="40%" Name="Project" Explode="true" ExplodeOffset="10%" ExplodeIndex="3">
                                <AccumulationDataLabelSettings Visible="true" Name="Percentage">
                                    <AccumulationChartDataLabelFont FontWeight="600" Color="white"></AccumulationChartDataLabelFont>
                                </AccumulationDataLabelSettings>
                            </AccumulationChartSeries>
                        </AccumulationChartSeriesCollection>
                    </SfAccumulationChart>
                </div>
            } */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card ">
            <div className="card-body ">{/* <ViewsPages /> */}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
