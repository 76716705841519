import React, { useState, useEffect } from "react";
import "./Layout.module.css";
import Footer from "./../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { setPublishCallback } from "../../services/httpService";
import { PublishWebSite } from "../../services/apiService";

const Layout = (props) => {
  // const { isAuthorizing } = props; // Destructure isLoading from props
  const auth = useAuth();
  const [isPublishEnabled, setIsPublishEnabled] = useState(false);
  const [isLoadingPublishState, setIsLoadingPublishState] = useState(false);

  useEffect(() => {
    // Pass the state setter to httpService
    setPublishCallback(setIsPublishEnabled);
  }, []);

  async function publishWebSite() {
    setIsLoadingPublishState(true);
    const results = await PublishWebSite();
    setIsLoadingPublishState(false);
    setIsPublishEnabled(false);
  }

  return (
    <>
      <div className="page">
        <Sidebar />
        {/* <SideBar Notifications="@Notifications" />  */}
        <main
          className="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
          id="mainComponent"
        >
          <nav
            className="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none"
            id="navbarBlur"
            data-scroll="false"
          >
            <div className="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                  <li className="breadcrumb-item text-sm">
                    <Link className="opacity-3 text-dark" to="/">
                      <svg
                        width="12px"
                        height="12px"
                        className="mb-1"
                        viewBox="0 0 45 40"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-1716.000000, -439.000000)"
                            fill="#252f40"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(0.000000, 148.000000)">
                                <path d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"></path>
                                <path d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </Link>
                  </li>
                  {/* @*<li className="breadcrumb-item text-sm"><a className="opacity-5 text-dark" href="javascript:;">Pages</a></li>*@
                            @*<li className="breadcrumb-item text-sm text-dark active" aria-current="page">Analytics</li>*@ */}
                </ol>
                {/* @*<h6 className="font-weight-bolder mb-0">Analytics</h6>*@ */}
              </nav>
              <div
                className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none "
                onClick={(e) => {
                  var sidenavToggler =
                    document.getElementsByClassName("sidenav-toggler")[0];
                  var sidenavShow =
                    document.getElementsByClassName("g-sidenav-show")[0];
                  var toggleNavbarMinimize =
                    document.getElementById("navbarMinimize");
                  if (!sidenavShow.classList.contains("g-sidenav-hidden")) {
                    sidenavShow.classList.remove("g-sidenav-pinned");
                    sidenavShow.classList.add("g-sidenav-hidden");
                    if (toggleNavbarMinimize) {
                      toggleNavbarMinimize.click();
                      toggleNavbarMinimize.setAttribute("checked", "true");
                    }
                  } else {
                    sidenavShow.classList.remove("g-sidenav-hidden");
                    sidenavShow.classList.add("g-sidenav-pinned");
                    if (toggleNavbarMinimize) {
                      toggleNavbarMinimize.click();
                      toggleNavbarMinimize.removeAttribute("checked");
                    }
                  }
                }}
              >
                <a href="#" className="nav-link text-body p-0">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </a>
              </div>
              <div
                className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
                id="navbar"
              >
                <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                  <div className="input-group input-group-outline">
                    <label className="form-label">Search..</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <ul className="navbar-nav  justify-content-end">
                  {isLoadingPublishState ? (
                    <>loading.. </>
                  ) : (
                    <button
                      className="btn btn-sm btn-primary"
                      disabled={!isPublishEnabled}
                      onClick={(e) => publishWebSite()}
                    >
                      Publish
                    </button>
                  )}

                  <li className="nav-item">
                    <a
                      class="nav-link text-body p-0 position-relative btnlogout"
                      disabled="@isDisabled"
                      onClick={async (e) => {
                        localStorage.clear();
                        // auth.removeUser();

                        try {
                          await auth.signoutRedirect(); // Full logout from the identity server
                          console.log("User signed out successfully");
                        } catch (error) {
                          console.error("Error during logout", error);
                        }
                      }}
                    >
                      <i class="ni ni-user-run"></i> Logout
                    </a>
                  </li>
                  <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                    <a
                      href="#"
                      className="nav-link text-body p-0"
                      id="iconNavbarSidenav"
                      onClick={(e) => {
                        const iconNavbarSidenav =
                          document.getElementById("iconNavbarSidenav");
                        const iconSidenav =
                          document.getElementById("iconSidenav");
                        const sidenav = document.getElementById("sidenav-main");
                        let body = document.getElementsByTagName("body")[0];
                        let className = "g-sidenav-pinned";

                        if (body.classList.contains(className)) {
                          body.classList.remove(className);
                          setTimeout(function () {
                            sidenav.classList.remove("bg-white");
                          }, 100);
                          sidenav.classList.remove("bg-transparent");
                        } else {
                          body.classList.add(className);
                          sidenav.classList.remove("bg-transparent");
                          iconSidenav.classList.remove("d-none");
                        }
                      }}
                    >
                      <div className="sidenav-toggler-inner">
                        <i className="sidenav-toggler-line"></i>
                        <i className="sidenav-toggler-line"></i>
                        <i className="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item px-3">
                    <a href="#" className="nav-link text-body p-0">
                      <i className="material-icons fixed-plugin-button-nav cursor-pointer">
                        settings
                      </i>
                    </a>
                  </li>
                  {/* @if (Notifications != null && Notifications.Where(x => !x.OpenByAppDate.HasValue).Any())
                            {
                                <li className="nav-item dropdown pe-2">
                                    <a href="javascript:;" className="nav-link text-body p-0 position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="material-icons cursor-pointer">
                                            notifications
                                        </i>
                                        <span className="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                                            <span className="small">@Notifications.Where(x => !x.OpenByAppDate.HasValue).Count()</span>
                                            <span className="visually-hidden">unread notifications</span>
                                        </span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end p-2 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                        @if (Notifications.Where(x => !x.OpenByAppDate.HasValue).Any(x => x.Type == NotificationType.Message.ToString()))
                                        {
                                            <li className="mb-2">
                                                <a className="dropdown-item border-radius-md" href="/notifications">
                                                    <div className="d-flex align-items-center py-1">
                                                        <span className="material-icons">email</span>
                                                        <div className="ms-2">
                                                            <h6 className="text-sm font-weight-normal my-auto">
                                                                @Notifications.Where(x => !x.OpenByAppDate.HasValue).Count(x => x.Type == NotificationType.Message.ToString()) new messages! Check new messages
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        }
                                        @if (Notifications.Where(x => !x.OpenByAppDate.HasValue).Any(x => x.Type == NotificationType.Comment.ToString()))
                                        {
                                            <li className="mb-2">
                                                <a className="dropdown-item border-radius-md" href="/notifications">
                                                    <div className="d-flex align-items-center py-1">
                                                        <span className="material-icons">email</span>
                                                        <div className="ms-2">
                                                            <h6 className="text-sm font-weight-normal my-auto">
                                                                @Notifications.Where(x => !x.OpenByAppDate.HasValue).Count(x => x.Type == NotificationType.Comment.ToString()) new comments! Check new messages
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }
                            else
                            {
                                <li className="nav-item dropdown pe-2">
                                    <a href="javascript:;" className="nav-link text-body p-0 position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="material-icons cursor-pointer">
                                            notifications
                                        </i>
                                        <span className="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                                            <span className="small">0</span>
                                            <span className="visually-hidden">unread notifications</span>
                                        </span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end p-2 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                        <li className="mb-2">
                                            <a className="dropdown-item border-radius-md" href="/notifications">
                                                <div className="d-flex align-items-center py-1">
                                                    <span className="material-icons">notifications</span>
                                                    <div className="ms-2">
                                                        <h6 className="text-sm font-weight-normal my-auto">
                                                            You dont have any new notification.
                                                        </h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            } */}
                </ul>
              </div>
            </div>
          </nav>
          {/* <!-- End Navbar --> */}
          <div className="container-fluid py-4" id="main-container">
            <div className="row">
              <div className="col-lg-12 position-relative z-index-2">
                {props.children}
              </div>
            </div>
            {<Footer />}
            {/* <Footer /> */}
          </div>
        </main>
        <div className="fixed-plugin">
          <a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
            <i className="material-icons py-2">settings</i>
          </a>
          <div className="card shadow-lg">
            <div className="card-header pb-0 pt-3">
              <div className="float-start">
                <h5 className="mt-3 mb-0">MySite Dashboard UI Config</h5>
              </div>
              <div className="float-end mt-4">
                <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                  <i className="material-icons">clear</i>
                </button>
              </div>
              {/* <!-- End Toggle Button --> */}
            </div>
            <hr className="horizontal dark my-1" />
            <div className="card-body pt-sm-3 pt-0">
              {/* <!-- Sidebar Backgrounds --> */}
              <div>
                <h6 className="mb-0">Sidebar Colors</h6>
              </div>
              <a href="#" className="switch-trigger background-color">
                <div className="badge-colors my-2 text-start">
                  <span
                    className="badge filter bg-gradient-primary active"
                    data-color="primary"
                  ></span>
                  <span
                    className="badge filter bg-gradient-dark"
                    data-color="dark"
                  ></span>
                  <span
                    className="badge filter bg-gradient-info"
                    data-color="info"
                  ></span>
                  <span
                    className="badge filter bg-gradient-success"
                    data-color="success"
                  ></span>
                  <span
                    className="badge filter bg-gradient-warning"
                    data-color="warning"
                  ></span>
                  <span
                    className="badge filter bg-gradient-danger"
                    data-color="danger"
                  ></span>
                </div>
              </a>
              {/* <!-- Sidenav Type --> */}
              <div className="mt-3">
                <h6 className="mb-0">Sidenav Type</h6>
                <p className="text-sm">
                  Choose between 2 different sidenav types.
                </p>
              </div>
              <div className="d-flex">
                {/* <button className="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" onClick="sidebarType(this)">Dark</button>
                        <button className="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-transparent" onClick="sidebarType(this)">Transparent</button>
                        <button className="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-white" onClick="sidebarType(this)">White</button> */}
              </div>
              <p className="text-sm d-xl-none d-block mt-2">
                You can change the sidenav type just on desktop view.
              </p>
              {/* <!-- Navbar Fixed --> */}
              <div className="mt-3 d-flex">
                <h6 className="mb-0">Navbar Fixed</h6>
                <div className="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    className="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    id="navbarFixed"
                  />
                </div>
              </div>
              <hr className="horizontal dark my-3" />
              <div className="mt-2 d-flex">
                <h6 className="mb-0">Sidenav Mini</h6>
                <div className="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    className="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    id="navbarMinimize"
                  />
                </div>
              </div>
              <hr className="horizontal dark my-3" />
              <div className="mt-2 d-flex">
                <h6 className="mb-0">Light / Dark</h6>
                <div className="form-check form-switch ps-0 ms-auto my-auto">
                  <input
                    className="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    id="dark-version"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
