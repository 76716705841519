import { Link } from "react-router-dom/cjs/react-router-dom";
import styles from "./Home.module.css";

const Home = (props) => {
  return (
    <>
      <div className="row col-12 ">
        <Link
          className={[
            "card card-stats col-lg-5 col-md-6 col-sm-6 m-3",
            styles.newPage,
          ].join(" ")}
          to="/page-wizard"
        >
          <div className="card-body ">
            <div className="row text-center">
              <div className="card-title">Add page</div>
            </div>
          </div>
        </Link>
        <a
          className={[
            "card card-stats col-lg-5 col-md-6 col-sm-6 m-3",
            styles.addCategory,
          ].join(" ")}
          href="/new-catrgory"
        >
          <div className="card-body ">
            <div className="row text-center">
              <div className="card-title">Add category</div>
            </div>
          </div>
        </a>

        <a
          className={[
            "card card-stats col-lg-5 col-md-6 col-sm-6 m-3",
            styles.invitationCode,
          ].join(" ")}
          href="/activate-invitation-code"
        >
          <div className="card-body ">
            <div className="row text-center">
              <div className="card-title">Apply an invitation code</div>
            </div>
          </div>
        </a>

        <a
          className={[
            "card card-stats col-lg-5 col-md-6 col-sm-6 m-3",
            styles.newWebsite,
          ].join(" ")}
          href="#"
        >
          <div className="card-body ">
            <div className="row text-center">
              <div className="card-title">Create new website</div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};
export default Home;
