import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ListGroup from "react-bootstrap/ListGroup";
import Uploader from "../../components/Basic/Uploader";
import { Button, Image, Space } from "antd";
import { getImage, getThumbnail } from "../../helpers/GlobalHelper";
import AppPagination from "../../components/Basic/AppPagination";
import { DeleteImage } from "../../services/apiService";
import { Form } from "react-bootstrap";

const ImageStorage = ({
  onSelectedImage,
  projectImages,
  setProjectImages,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(Math.ceil(total / itemsPerPage));
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [deletingImageId, setDeletingImageId] = useState(null);

  function onPageItemsChange(value) {
    setItemsPerPage(parseInt(value));
    setPageCount(1);
  }

  useEffect(() => {
    if (!isLoading) {
      init();
    }
  }, [isLoading]);

  useEffect(() => {
    init();
  }, []);

  function init() {
    setTotal(projectImages ? projectImages.length : 0);
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setPageCount(
      Math.ceil((projectImages ? projectImages.length : 0) / itemsPerPage)
    );
    setTotalPages(Math.ceil(total / itemsPerPage));
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update currentPage here
  };
  return (
    <>
      <Tabs
        defaultActiveKey="stock"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="stock" title="Image stock (xx)">
          <div className="row">
            <div className="col-md-12">
              <div>
                <div>
                  {selectedImageId && (
                    <>
                      <button
                        className="btn btn-sm bg-gradient-dark me-3"
                        onClick={async (e) => {
                          onSelectedImage(selectedImageId);
                        }}
                      >
                        Select
                      </button>
                      <button
                        className="btn btn-sm bg-gradient-dark"
                        onClick={async (e) => {
                          setDeletingImageId(selectedImageId);
                          await DeleteImage(selectedImageId);
                          const indexToRemove = projectImages.findIndex(
                            (obj) => obj === selectedImageId
                          );
                          if (indexToRemove !== -1) {
                            // Remove the object from the array
                            const newArray = [...projectImages];
                            newArray.splice(indexToRemove, 1);
                            // Update the state with the modified array
                            setProjectImages(newArray);
                            setDeletingImageId(null);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="">
            {projectImages && projectImages.length > 0 ? (
              <div className="row">
                {projectImages
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    (currentPage - 1) * itemsPerPage + itemsPerPage
                  )
                  .map((imageId) => {
                    return (
                      <div className="col">
                        {deletingImageId == imageId ? (
                          <span>Deleting..</span>
                        ) : (
                          <>
                            <img
                              className={[
                                "cursor-pointer mb-4",
                                selectedImageId == imageId
                                  ? "selectedImage"
                                  : "",
                              ].join(" ")}
                              src={getThumbnail(imageId)}
                              width={200}
                              onClick={(e) =>
                                selectedImageId != imageId
                                  ? setSelectedImageId(imageId)
                                  : setSelectedImageId(null)
                              }
                            />
                            {/* <Image
                          className={[
                            "cursor-pointer",
                            selectedImageId == imageId ? "selectedImage" : "",
                          ].join(" ")}
                          onClick={(e) =>
                            selectedImageId != imageId
                              ? setSelectedImageId(imageId)
                              : setSelectedImageId(null)
                          }
                          preview={false}
                          width={200}
                          src={getImage(imageId)}
                          placeholder={
                            <Image
                              preview={false}
                              src={getThumbnail(imageId)}
                              width={200}
                            />
                          }
                        /> */}
                          </>
                        )}
                      </div>
                    );
                  })}
                <div className="row">
                  <div className="col">
                    <AppPagination
                      total={total}
                      itemsPerPage={itemsPerPage}
                      onPageChange={handlePageChange}
                      totalPages={totalPages}
                    />
                  </div>
                  <div className="col">
                    <div>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control
                          as="select"
                          defaultValue="5"
                          onChange={(e) => {
                            console.log(e.target.value);
                            onPageItemsChange(e.target.value);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col">
                    <span className="fw-bold mt-3">
                      Total:
                      {projectImages ? projectImages.length : 0}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <>Nothing found.</>
            )}

            <div className="col-md-3"></div>
            <div className="col-md-9"></div>
          </div>
        </Tab>
        <Tab eventKey="nameImage" title="Upload new image">
          <div>
            <Uploader onSuccessUpload={onSelectedImage} />
          </div>
        </Tab>
      </Tabs>
    </>
  );
};
export default ImageStorage;
