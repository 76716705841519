import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  DeleteMessage,
  GetProjectMessages,
  MessageReply,
} from "../../services/apiService";
import Textarea from "../../components/Basic/TextArea";
import Input from "../../components/Basic/Input";
import { convertDate } from "../../Converts/DateTimeConverter";

const Messages = ({}) => {
  const { messageId } = useParams();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isActionRunning, setIsActionRunning] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [replyMessage, setReplyMessage] = useState("");
  const [selectedNav, setSelectedNav] = useState(null);
  const [total, setTotal] = useState(0);
  const [messages, setMessages] = useState(null);
  const [messagesBackup, setMessagesBackup] = useState(null);
  const [search, setSearch] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoadingPage) {
          setIsLoadingPage(false);
          const response = await GetProjectMessages();
          setMessages(response);
          setMessagesBackup(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingPage(false);
      }
    };
    fetchData();
  }, []);

  function initList(navType) {
    setSelectedNav(navType);
    setSelectedMessage(null);
    if (messagesBackup && messagesBackup.length > 0) {
      switch (navType) {
        case "spam": {
          setMessages([...messagesBackup.filter((x) => x.spamDate)]);
          break;
        }

        case "all": {
          setMessages([...messagesBackup]);
          break;
        }

        case "unread": {
          setMessages([...messagesBackup.filter((x) => !x.readDate)]);
          break;
        }

        default:
          break;
      }
    }
  }
  async function reply(tmpReplyMessage) {
    if (selectedMessage) {
      setIsEditMode(false);

      setIsActionRunning(true);
      const result = await MessageReply(
        selectedMessage.messageId,
        tmpReplyMessage
      );
      const updatedMessagesBackup = messagesBackup.map((obj) => {
        if (obj.messageId === selectedMessage.messageId) {
          return {
            ...obj,
            replyContent: tmpReplyMessage,
            replyDate: tmpReplyMessage ? new Date().toISOString() : null,
          };
        }
        return obj;
      });

      const updatedMessages = messages.map((obj) => {
        if (obj.messageId === selectedMessage.messageId) {
          return {
            ...obj,
            replyContent: tmpReplyMessage,
            replyDate: tmpReplyMessage ? new Date().toISOString() : null,
          };
        }
        return obj;
      });
      setMessagesBackup([...updatedMessagesBackup]);
      setMessages([...updatedMessages]);
      const tmpSelectedMessage = selectedMessage;
      tmpSelectedMessage.replyContent = tmpReplyMessage;
      tmpSelectedMessage.replyDate = tmpReplyMessage
        ? new Date().toISOString()
        : null;
      setSelectedMessage(tmpSelectedMessage);
      setReplyMessage(null);
      setIsActionRunning(false);
    }
  }
  async function deleteMessage() {
    if (selectedMessage) {
      const result = await DeleteMessage(selectedMessage.messageId);
      const filteredMessages = messages.filter(
        (message) => message.id !== selectedMessage.messageId
      );
      setMessages(filteredMessages);
    }
  }
  return (
    <>
      <div className="">
        <div className="card card-body">
          <div className="row">
            <div className="row mt-4">
              <div className="col-md-5 col-lg-4">
                <div className="card blur shadow-blur max-height-vh-70 overflow-auto overflow-x-hidden mb-5 mb-lg-0">
                  <div className="card-header p-3">
                    <div className="row">
                      <div className="nav-wrapper position-relative end-0">
                        <ul
                          className="nav nav-pills nav-fill p-1"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link mb-0 px-0 py-1 ${
                                selectedNav == "unread" ? "active" : ""
                              }`}
                              onClick={(e) => {
                                initList("unread");
                              }}
                              data-bs-toggle="tab"
                              href="#unread-tab"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="true"
                            >
                              Unread
                              {messagesBackup && messagesBackup.length > 0 && (
                                <>
                                  {
                                    messagesBackup.filter((x) => !x.ReadDate)
                                      .length
                                  }
                                </>
                              )}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link mb-0 px-0 py-1 ${
                                selectedNav == "all" ? "active" : ""
                              }`}
                              onClick={(e) => {
                                initList("all");
                              }}
                              data-bs-toggle="tab"
                              href="#all-tab"
                              role="tab"
                              aria-controls="dashboard"
                              aria-selected="false"
                            >
                              All
                              {messagesBackup && messagesBackup.length > 0 ? (
                                <>{messagesBackup.length}</>
                              ) : (
                                <>0</>
                              )}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link mb-0 px-0 py-1 ${
                                selectedNav == "spam" ? "active" : ""
                              }`}
                              onClick={(e) => {
                                initList("spam");
                              }}
                              data-bs-toggle="tab"
                              href="#spam-tab"
                              role="tab"
                              aria-controls="dashboard"
                              aria-selected="false"
                            >
                              Spam
                              {messagesBackup && messagesBackup.length > 0 && (
                                <>
                                  {
                                    messagesBackup.filter((x) => x.spamDate)
                                      .length
                                  }
                                </>
                              )}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Input
                      placeholder={"Search by email"}
                      onChange={(e) => {
                        const tmpSearch = e.target.value ? e.target.value : "";
                        setSearch(tmpSearch);

                        if (tmpSearch) {
                          if (messagesBackup && messagesBackup.length > 0) {
                            setMessages([
                              ...messagesBackup
                                .filter(
                                  (x) =>
                                    x.senderEmail &&
                                    x.senderEmail
                                      .toLowerCase()
                                      .includes(tmpSearch.toLowerCase())
                                )
                                .sort(
                                  (a, b) =>
                                    new Date(b.createDate) -
                                    new Date(a.createDate)
                                ),
                            ]);
                          }
                        } else {
                          if (messagesBackup && messagesBackup.length > 0) {
                            setMessages([...messagesBackup]);
                          }
                        }
                      }}
                    />
                  </div>
                  {isLoadingPage ? (
                    <>loading..</>
                  ) : (
                    <>
                      {messages && messages.length > 0 && (
                        <>
                          {messages.map((message) => {
                            return (
                              <>
                                <div className="card-body p-2">
                                  <a
                                    onClick={(e) => setSelectedMessage(message)}
                                    className="d-block p-2"
                                  >
                                    <div
                                      className={`d-flex p-2 text-truncate ${
                                        selectedMessage &&
                                        selectedMessage.messageId ==
                                          message.messageId
                                          ? "border-radius-lg bg-gradient-primary"
                                          : message && !message.readDate
                                          ? "border-radius-lg bg-light-gray"
                                          : ""
                                      }`}
                                    >
                                      <div className="ms-3 text-truncate">
                                        <h6
                                          className={`${
                                            selectedMessage &&
                                            selectedMessage.messageId ==
                                              message.messageId
                                              ? "text-white"
                                              : "text-muted"
                                          } mb-0`}
                                        >
                                          {message.senderEmail}
                                        </h6>
                                        <p
                                          className={`${
                                            selectedMessage &&
                                            selectedMessage.messageId ==
                                              message.messageId
                                              ? "text-white"
                                              : "text-muted"
                                          } text-xs mb-2`}
                                        >
                                          {convertDate(message.createDate)}
                                        </p>
                                        <span
                                          className={`${
                                            selectedMessage &&
                                            selectedMessage.messageId ==
                                              message.messageId
                                              ? "text-white"
                                              : "text-muted"
                                          } text-sm col-11 p-0 text-truncate d-block`}
                                        >
                                          {message.content}
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-7 col-lg-8">
                <div className="card blur shadow-blur max-height-vh-70">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg p-3">
                      <div className="row">
                        <div className="col-md-9 col-lg-10">
                          <div className="d-flex align-items-center">
                            <div className="ms-3">
                              <h6 className="mb-0 d-block text-white">
                                {selectedMessage ? (
                                  <>{selectedMessage.senderEmail}</>
                                ) : (
                                  <>Select message..</>
                                )}
                              </h6>
                              {selectedMessage &&
                                selectedMessage.createDate && (
                                  <>{convertDate(selectedMessage.createDate)}</>
                                )}
                              <span className="text-sm text-white opacity-8"></span>
                            </div>
                          </div>
                        </div>
                        {selectedMessage && (
                          <div className="col-2 my-auto text-align-end">
                            {isDeleting ? (
                              <>loading..</>
                            ) : (
                              <button
                                onClick={async (e) => await deleteMessage()}
                                className="btn btn-icon-only text-white mb-0 me-3 me-sm-0"
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Delete message"
                              >
                                <i className="bi bi bi-trash app-icon"></i>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {selectedMessage && (
                    <>
                      <div className="card-body overflow-auto overflow-x-hidden">
                        <div className="row justify-content-start mb-4">
                          <div className="col-auto">
                            <div className="card ">
                              <div className="card-body py-2 px-3">
                                <p className="mb-1">
                                  {selectedMessage.content}
                                </p>
                                <div className="d-flex align-items-center text-sm opacity-6">
                                  <i className="ni ni-check-bold text-sm me-1"></i>
                                  <small>
                                    {convertDate(selectedMessage.createDate)}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedMessage.replyDate && (
                          <div className="row justify-content-end text-right mb-4">
                            <div className="col-auto">
                              <div className="card bg-gradient-primary">
                                <div className="card-body py-2 px-3 text-white">
                                  <p className="mb-1">
                                    {selectedMessage.replyContent}
                                    <br />
                                  </p>
                                  <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
                                    <i className="ni ni-check-bold text-sm me-1"></i>
                                    <small>
                                      {convertDate(selectedMessage.replyDate)}
                                    </small>
                                  </div>
                                </div>
                                <div className="col-12 d-flex">
                                  <div>
                                    {isActionRunning ? (
                                      <div className="btn bg-gradient-primary mb-0 float-end disabled">
                                        <>loading..</>
                                      </div>
                                    ) : (
                                      <button
                                        className="btn btn-sm bg-gradient-dark"
                                        onClick={async (e) => {
                                          setIsActionRunning(true);
                                          const tmpM = JSON.parse(
                                            JSON.stringify(selectedMessage)
                                          );
                                          const tmpMessage = selectedMessage;
                                          tmpMessage.replyDate = null;
                                          tmpMessage.replyContent = null;
                                          setSelectedMessage(tmpMessage);
                                          setReplyMessage(tmpM.replyContent);
                                          setIsEditMode(true);
                                          setIsActionRunning(false);
                                        }}
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </div>
                                  <div>
                                    {isActionRunning ? (
                                      <div className="btn bg-gradient-primary mb-0 float-end disabled">
                                        <>loading..</>
                                      </div>
                                    ) : (
                                      <button
                                        className="btn btn-sm bg-gradient-dark"
                                        onClick={async (e) => {
                                          reply("");
                                        }}
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {!selectedMessage.replyContent && (
                        <div className="card-footer d-block">
                          <Textarea
                            rows={4}
                            className="mb-2"
                            placeholder="Type your reply.."
                            maxLength={255}
                            onChange={(e) => {
                              setReplyMessage(e.target.value);
                            }}
                            value={replyMessage}
                          />
                          {isActionRunning ? (
                            <div className="btn bg-gradient-primary mb-0 float-end disabled">
                              <>loading..</>
                            </div>
                          ) : (
                            <>
                              <button
                                className={`btn bg-gradient-primary mb-0 float-end ${
                                  !replyMessage || isActionRunning
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={async (e) => await reply(replyMessage)}
                              >
                                <i className="material-icons">send</i>
                              </button>
                              {isEditMode && (
                                <button
                                  className={`btn bg-gradient-primary mb-0 float-end me-2 ${
                                    !replyMessage || isActionRunning
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={async (e) => {
                                    selectedMessage.replyContent = replyMessage;
                                    selectedMessage.replyDate = replyMessage
                                      ? new Date().toISOString()
                                      : null;
                                    setSelectedMessage(selectedMessage);
                                    setReplyMessage(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Messages;
