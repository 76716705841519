import { Get, Put, Post, Delete } from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const getApiEndpoint = () => {
  return apiEndpoint;
};
export function GenerateUrl(url) {
  return `${apiEndpoint}${url}`;
}

export async function GetProjectLanguages() {
  return await Get(GenerateUrl("project/languages"));
}

export async function GetAllProjectPages() {
  return await Get(GenerateUrl("page/all"));
}

export async function GetAllProjectPagesBasic() {
  return await Get(GenerateUrl("page/parent/all/basic"));
}

export async function GetAllProjectParents() {
  return await Get(GenerateUrl("page/parent/all"));
}

export async function UpdatePage(page) {
  return await Put(GenerateUrl("page"), page);
}

export async function GetPageFiles(pageId) {
  return await Get(GenerateUrl(`page/${pageId}/files`));
}

export async function GetPageVideos(pageId) {
  return await Get(GenerateUrl(`page/${pageId}/videos`));
}

export async function GetProjectImages() {
  return await Get(GenerateUrl(`image/project`));
}

export async function GetProjectMessages() {
  return await Get(GenerateUrl(`message/all`));
}

export async function DeleteImage(imageId) {
  return await Delete(GenerateUrl(`image/${imageId}`));
}

export async function DeleteFile(fileId, pageId) {
  return await Delete(GenerateUrl(`file/${fileId}/page/${pageId}`));
}

export async function DeleteVideo(videoId, pageId) {
  return await Delete(GenerateUrl(`page/${pageId}/video/${videoId}`));
}

export async function UpdateVideos(videos, pageId) {
  return await Put(GenerateUrl(`page/${pageId}/video/list`), videos);
}

export async function UpdateFileAction(fileId, action) {
  return await Put(GenerateUrl(`file/${fileId}/action/${action}`));
}

export async function MessageReply(messageId, replyMessage) {
  return await Put(GenerateUrl(`message/${messageId}/reply`), {
    replyContent: replyMessage,
  });
}

export async function DeleteMessage(messageId) {
  return await Delete(GenerateUrl(`message/${messageId}`));
}

export async function GetAllAutomationTasks() {
  return await Get(GenerateUrl(`automation/all`));
}

export async function AddAutomationTask(task) {
  return await Post(GenerateUrl(`automation`), task);
}

export async function UpdateAutomationTask(task) {
  return await Put(GenerateUrl(`automation`), task);
}

export async function DeleteAutomationTask(taskIds) {
  return await Delete(GenerateUrl(`automation/list`), taskIds);
}

export async function GetAllComments() {
  return await Get(GenerateUrl(`comment/project`));
}

export async function ApproveComment(commentId, state) {
  return await Put(GenerateUrl(`comment/${commentId}/approve/${state}`));
}

export async function MarkAsSpamComment(commentId, state) {
  return await Put(GenerateUrl(`comment/${commentId}/spam/${state}`));
}

export async function ReplyComment(commentId, message) {
  return await Post(GenerateUrl(`comment/${commentId}/reply`), message);
}

export async function DeleteReplyComment(commentId) {
  return await Delete(GenerateUrl(`comment/${commentId}/reply`));
}

export async function DeleteComment(commentId) {
  return await Delete(GenerateUrl(`comment/${commentId}`));
}

export async function GetAllMainPage(type) {
  return await Get(GenerateUrl(`mainPageSettings/${type}`));
}

export async function AddMainPage(mainpage) {
  return await Post(GenerateUrl(`mainPageSettings`), mainpage);
}

export async function DeleteMainPage(mainpageId) {
  return await Delete(GenerateUrl(`mainPageSettings/${mainpageId}`));
}

export async function ChangeMainPageOrder(mainpageId, order = 0) {
  return await Put(
    GenerateUrl(`mainPageSettings/${mainpageId}/order/${order}`)
  );
}

export async function GetRangeViews(startDate, endDate) {
  return await Get(GenerateUrl(`views/range/${startDate}/${endDate}`));
}

export async function GetTotalViews() {
  return await Get(GenerateUrl(`views/total`));
}

export async function GetAllSocialMedia() {
  return await Get(GenerateUrl(`socialmedia`));
}

export async function AddSocialMedia(data) {
  return await Post(GenerateUrl(`socialmedia`), data);
}

export async function UpdateSocialMedia(data) {
  return await Put(GenerateUrl(`socialmedia/${data.id}`), data);
}

export async function DeleteSocialMedia(dataId) {
  return await Delete(GenerateUrl(`socialmedia/${dataId}`));
}

export async function PublishWebSite() {
  return await Post(GenerateUrl(`project/publish`));
}
