import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "./css/material-dashboard.css?v=3.0.6";
import "./css/site.css?v=3.0.6";
import { registerLicense } from "@syncfusion/ej2-base";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import ErrorBoundary from "./helpers/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
const oidcConfig = {
  // onSignIn: (user) => {
  //   if (!user) {
  //   } else {
  //     if (user.access_token) {
  //       localStorage.setItem("access_token", user.access_token);
  //       localStorage.setItem("userData", JSON.stringify(user.profile));
  //       // setUser(user);
  //       // history.push("/");
  //       window.location.href = "/"; // Redirect to the root URL
  //       // setIsAuthorized(true);
  //     } else {
  //     }
  //   }
  // },
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY, //"https://localhost:44395", // Replace with your OpenNicDT authority URL
  client_id: process.env.REACT_APP_IDENTITY_CLIENTID, //"omadot_dash_app4", // Replace with your client ID
  redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECTURL, //"http://localhost:3000/login", // Replace with your redirect URI
  scope: process.env.REACT_APP_IDENTITY_SCOPE, //"offline_access omadot_api_scope", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  loadUserInfo: true,
  post_logout_redirect_uri:
    process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECTURL ||
    "http://localhost:3000/loggedout", // URL to redirect after logout
};

// Registering Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpecnVRQ2BcVUJ1XUY="
);

root.render(
  <AuthProvider {...oidcConfig}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
