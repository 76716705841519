import React, { useState, useEffect } from "react";
import {
  ApproveComment,
  DeleteComment,
  DeleteReplyComment,
  GetAllComments,
  MarkAsSpamComment,
  ReplyComment,
} from "../../services/apiService";
import { Modal, Table } from "react-bootstrap";
import { convertDate } from "../../Converts/DateTimeConverter";
import Input from "../../components/Basic/Input";

const Comments = ({ projectPagesBasic }) => {
  const [comments, setComments] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isActionRunning, setIsActionRunning] = useState(false);
  const [replyComment, setReplyComment] = useState(null);
  const [replyMessage, setReplyMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoadingPage) {
          setIsLoadingPage(false);
          const response = await GetAllComments();
          setComments(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingPage(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (projectPagesBasic && projectPagesBasic.length > 0) {
    }
  }, [projectPagesBasic]);

  async function ApproveType(commentId, state) {
    if (commentId) {
      setIsActionRunning(true);
      const result = await ApproveComment(commentId, state);
      if (result) {
        const updatedItems = comments.map((item) =>
          item.commentId === commentId
            ? { ...item, approveDate: state ? new Date().toISOString() : null }
            : item
        );
        setComments([...updatedItems]);
      }
      setIsActionRunning(false);
    }
  }
  async function MarkAsNSpam(commentId, state) {
    if (commentId) {
      setIsActionRunning(true);
      const result = await MarkAsSpamComment(commentId, state);
      if (result) {
        const updatedItems = comments.map((item) =>
          item.commentId === commentId
            ? {
                ...item,
                markAsSpamDate: state ? new Date().toISOString() : null,
              }
            : item
        );
        setComments([...updatedItems]);
      }
      setIsActionRunning(false);
    }
  }
  async function Delete(commentId) {
    if (commentId) {
      setIsActionRunning(true);
      const result = await DeleteComment(commentId);
      if (result) {
        const updatedItems = comments.filter((x) => x.commentId != commentId);
        setComments([...updatedItems]);
      }
      setIsActionRunning(false);
    }
  }
  async function SaveReply() {
    if (replyComment) {
      setIsActionRunning(true);
      const result = await ReplyComment(replyComment.commentId, replyMessage);
      if (result) {
        const updatedItems = comments.map((item) =>
          item.commentId === replyComment.commentId
            ? {
                ...item,
                replyContent: replyMessage,
                replyDate: new Date().toISOString(),
              }
            : item
        );
        setReplyComment(null);
        setReplyComment(null);
        setComments([...updatedItems]);
      }

      setIsActionRunning(false);
    }
  }
  async function ReplyModeOnOff(comment) {
    if (comment) {
      setReplyComment(comment);
    }
  }

  const getPageName = (pageTopLevelId) => {
    if (projectPagesBasic && projectPagesBasic.length > 0) {
      const data = projectPagesBasic
        .filter((x) => x.title)
        .find((x) => x.title.levelId == pageTopLevelId);
      return data ? data.title.name : "-";
    }
  };
  return (
    <>
      {isLoadingPage ? (
        <>Loading..</>
      ) : (
        <>
          {replyComment && (
            <Modal show={true} onHide={(e) => setReplyComment(null)}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Input
                  placeholder="Reply content"
                  onChange={(e) => setReplyMessage(e.target.value)}
                  value={replyMessage}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-sm bg-gradient-dark me-1 aclickable"
                  onClick={(e) => setReplyComment(null)}
                >
                  Close
                </button>
                <button
                  className="btn btn-sm bg-gradient-dark me-1 aclickable"
                  onClick={async (e) => SaveReply()}
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {comments && comments.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Page</th>
                    <th>Name</th>
                    <th>Content</th>
                    <th>Reply Date</th>
                    <th>Reply</th>
                    <th>Is Approved</th>
                    <th>Is SPAM</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {comments.map((comment) => {
                    return (
                      <tr>
                        <td>{convertDate(comment.createDate)}</td>
                        <td>
                          {projectPagesBasic &&
                            projectPagesBasic.length > 0 && (
                              <>{getPageName(comment.topLevelId)}</>
                            )}
                        </td>
                        <td>{comment.name}</td>
                        <td>
                          {comment.content && comment.content.length > 0
                            ? comment.content.substring(0, 50)
                            : ""}
                        </td>
                        <td>
                          {comment.replyDate
                            ? convertDate(comment.replyDate)
                            : "-"}
                        </td>
                        <td>
                          <span>
                            {" "}
                            {comment.replyContent &&
                            comment.replyContent.length > 0
                              ? comment.replyContent.substring(0, 50)
                              : ""}
                          </span>
                          {comment.replyContent && (
                            <div className="col-12 d-flex">
                              <div>
                                {isActionRunning ? (
                                  <div className="btn bg-gradient-primary mb-0 float-end disabled">
                                    <>loading..</>
                                  </div>
                                ) : (
                                  <button
                                    className="btn btn-sm bg-gradient-dark"
                                    onClick={async (e) => {
                                      setIsActionRunning(true);
                                      setReplyComment(comment);
                                      setReplyMessage(comment.replyContent);
                                      setIsActionRunning(false);
                                    }}
                                  >
                                    Edit
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          {comment.approveDate ? (
                            <span class="badge bg-success">
                              Approved:
                              {convertDate(comment.approveDate)}
                            </span>
                          ) : (
                            <span class="badge bg-warning text-dark">
                              Not yet
                            </span>
                          )}
                        </td>
                        <td>
                          {comment.markAsSpamDate ? (
                            <span class="badge bg-danger">
                              Spam:
                              {convertDate(comment.markAsSpamDate)}
                            </span>
                          ) : (
                            <span class="badge bg-success text-dark">
                              Not spam
                            </span>
                          )}
                        </td>
                        <td>
                          <div>
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-dark me-1 aclickable"
                              disabled={isActionRunning}
                              onClick={async (e) => {
                                await ApproveType(
                                  comment.commentId,
                                  !comment.approveDate ? true : false
                                );
                              }}
                            >
                              {comment.approveDate ? "Disapprove" : "Approve"}
                            </button>
                            {!comment.replyDate && (
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-dark me-1 aclickable"
                                disabled={isActionRunning}
                                onClick={async (e) =>
                                  await ReplyModeOnOff(comment)
                                }
                              >
                                Reply
                              </button>
                            )}
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-dark me-1 aclickable"
                              disabled={isActionRunning}
                              onClick={async (e) =>
                                await MarkAsNSpam(
                                  comment.commentId,
                                  !comment.markAsSpamDate ? true : false
                                )
                              }
                            >
                              {comment.markAsSpamDate
                                ? "Mark as NOT SPAM"
                                : "Mark as SPAM"}
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-dark me-1 aclickable"
                              disabled={isActionRunning}
                              onClick={async (e) => {
                                await Delete(comment.commentId);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>Nothing found</>
          )}
        </>
      )}
    </>
  );
};
export default Comments;
