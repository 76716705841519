import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch, Space } from "antd";

const Toggle = ({
  checked = <CheckOutlined />,
  unChecked = <CloseOutlined />,
  defaultChecked,
  onChange,
  className,
}) => {
  return (
    <>
      <Space direction="vertical">
        <Switch
          checkedChildren={checked}
          unCheckedChildren={unChecked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          className={className}
        />
      </Space>
    </>
  );
};
export default Toggle;
