import { toast, Bounce } from "react-toastify";
import { trackException, trackRequest } from "./telemetry";

// Assuming you have a setIsPublishEnabled function passed to httpService
let setIsPublishEnabled;

export const setPublishCallback = (callback) => {
  setIsPublishEnabled = callback;
};

export const generateHeaders = (withJson = true) => {
  const accessToken = localStorage.getItem("access_token");
  const userDataStr = localStorage.getItem("userData");

  if (accessToken && userDataStr) {
    const userData = JSON.parse(userDataStr);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "x-roles": userData.roles,
      "x-pId": userData.projectId,
      "x-lan": "fi-FI",
      "x-tv": userData.tokenVersion,
    };
    if (withJson) {
      headers["Content-Type"] = "application/json";
    }
    return headers;
  }
};
async function SendAsync(url, method, data = null) {
  const startTime = Date.now();

  try {
    const accessToken = localStorage.getItem("access_token");
    const userDataStr = localStorage.getItem("userData");

    if (accessToken && userDataStr) {
      const userData = JSON.parse(userDataStr);

      const request = {
        method: method,
        headers: generateHeaders(),
      };
      if (data) {
        request["body"] = JSON.stringify(data);
      }
      const response = await fetch(url, request);

      if (response) {
        const isPublish = response.headers.get("PubState") === "1";

        if (setIsPublishEnabled) {
          setIsPublishEnabled(isPublish);
        }
      }
      const endTime = Date.now();
      const responseTime = endTime - startTime;

      trackRequest(url, url, responseTime, response.ok);

      if (!response.ok) {
        // Handle non-successful responses (e.g., 404, 500)
        toast.error(`HTTP error! Status: ${response.status}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      toast.success(`Success operation`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return await response.json();
      //   return { data: responseData, responseTime: responseTime };
    }
  } catch (error) {
    // Handle network errors or exceptions
    console.error("Error:", error);
    trackException(error);
    return { error: error.message, responseTime: null };
  }
}

export async function Get(url) {
  return SendAsync(url, "GET");
}

export async function Post(url, data) {
  return SendAsync(url, "POST", data);
}

export async function Put(url, data) {
  return SendAsync(url, "PUT", data);
}

export async function Delete(url, data) {
  return SendAsync(url, "DELETE", data);
}
