import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { generateHeaders } from "../../services/httpService";
import { getApiEndpoint } from "../../services/apiService";

const Uploader = ({ onRemove, onSuccessUpload }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const uploadProps = {
    accept: "image/*", // Only allow image files
    name: "uploadFiles",
    action: `${getApiEndpoint()}image/save`,
    listType: "picture",
    maxCount: 1,
    onRemove: onRemove,
    headers: generateHeaders(false),
    disabled: isDisabled,
    // onSuccess(file, response) {
    //   // Access the value of the "name" header from the response
    //   const nameHeaderValue = response.headers.get("name");
    //   console.log("Name header value:", nameHeaderValue);

    //   // Handle the success as needed
    //   console.log(`${file.name} file uploaded successfully`);
    // },

    onChange(info) {
      setIsDisabled(true);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(`${info.file.name} file uploaded successfully`);
        if (onSuccessUpload && info.file.response && info.file.response.id) {
          console.log(`${info.file.response.id} file id`);
          onSuccessUpload(info.file.response.id);
        }
        setIsDisabled(false);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file upload failed.`);
        setIsDisabled(false);
      }
    },
    multiple: false,
  };
  return (
    <>
      <Upload {...uploadProps}>
        <Button disabled={isDisabled} icon={<UploadOutlined />}>
          Upload
        </Button>
      </Upload>
    </>
  );
};
export default Uploader;
