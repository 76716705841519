import React from "react";
import { Radio } from "antd";

// const optionsWithDisabled = [
//   {
//     label: "Apple",
//     value: "Apple",
//   },
//   {
//     label: "Pear",
//     value: "Pear",
//   },
//   {
//     label: "Orange",
//     value: "Orange",
//     disabled: true,
//   },
// ];
const RadioButton = ({ dataSource, defaultValue, onChange }) => {
  //   const onChange1 = ({ target: { value } }) => {
  //     console.log("radio1 checked", value);
  //     setValue1(value);
  //   };

  return (
    <>
      <Radio.Group
        options={dataSource}
        onChange={onChange}
        value={defaultValue}
      />
    </>
  );
};
export default RadioButton;
