import { Input } from "antd";

const { TextArea } = Input;

const Textarea = ({
  rows = 4,
  className = "mb-2",
  placeholder,
  maxLength = 255,
  onChange,
  value,
  disabled = false,
}) => {
  console.log();
  return (
    <>
      <TextArea
        rows={rows}
        className={className}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
    </>
  );
};
export default Textarea;
