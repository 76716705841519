import React, { useState, useEffect } from "react";
import {
  AddSocialMedia,
  DeleteSocialMedia,
  GetAllSocialMedia,
  UpdateSocialMedia,
} from "../../services/apiService";
import { convertDate } from "../../Converts/DateTimeConverter";
import Modal from "../../components/Basic/Modal";
import Input from "../../components/Basic/Input";

const SocialMedia = (props) => {
  const [list, setList] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isActionRunning, setIsActionRunning] = useState(false);
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoadingPage) {
          setIsLoadingPage(false);
          const response = await GetAllSocialMedia();
          setList(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingPage(false);
      }
    };
    fetchData();
  }, []);

  async function ModifyItem() {
    setIsActionRunning(true);
    if (editItem) {
      if (editItem.id) {
        const result = await UpdateSocialMedia(editItem);
        if (result) {
          const updatedList = list.map((item) =>
            item.id === editItem.id
              ? {
                  ...item,
                  name: editItem.name,
                  link: editItem.link,
                  updateDate: new Date().toISOString(),
                }
              : item
          );
          setList(updatedList);
        }
      } else {
        const resultId = await AddSocialMedia(editItem);
        if (resultId) {
          editItem.id = resultId;
          editItem.createDate = new Date().toISOString();

          const updatedData = [...list, editItem];
          setList(updatedData); // Add the new item to the array and update the state
        }
      }
    }
    setEditItem(null);
    setIsActionRunning(false);
  }
  async function DeleteItem(itemId) {
    setIsActionRunning(true);
    if (itemId) {
      const result = await DeleteSocialMedia(itemId);
      if (result) {
        const tmpList = list.filter((x) => x.id != itemId);
        setList([...tmpList]);
      }
    }
    setIsActionRunning(false);
  }
  return (
    <>
      {isLoadingPage ? (
        <>loading..</>
      ) : (
        <>
          <div className="justify-content-between d-flex">
            <div>
              <button
                role="button"
                disabled={isActionRunning}
                rel="tooltip"
                className="btn btn-primary btn-sm me-2"
                onClick={(e) => setEditItem({ name: "", link: "" })}
              >
                New <span className="small"></span>
              </button>
            </div>
          </div>
          {editItem && (
            <Modal
              show={true}
              fullscreen={false}
              onHide={(e) => setEditItem(null)}
              FooterButtons={(e) => {
                return (
                  <>
                    <button
                      className="btn btn-light btn-sm me-2"
                      disabled={isActionRunning}
                      onClick={(e) => setEditItem(null)}
                    >
                      Close
                    </button>
                    <button
                      disabled={isActionRunning}
                      className="btn btn-primary btn-sm me-2"
                      onClick={(e) => ModifyItem()}
                    >
                      Save
                    </button>
                  </>
                );
              }}
            >
              <div className="form-group">
                <Input
                  placeholder="Name"
                  readOnly={isActionRunning}
                  value={editItem ? editItem.name : ""}
                  onChange={(e) => {
                    setEditItem((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="form-group">
                <Input
                  placeholder="Link"
                  readOnly={isActionRunning}
                  value={editItem ? editItem.link : ""}
                  onChange={(e) => {
                    setEditItem((prevState) => ({
                      ...prevState,
                      link: e.target.value,
                    }));
                  }}
                />
              </div>
            </Modal>
          )}
          {list && list.length > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table align-items-center mb-0 table-hover">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Name
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Link
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Create
                      </th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                        Update
                      </th>
                      <th className="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((x) => {
                      return (
                        <tr
                          className="cursor-pointer"
                          onClick={(e) => {
                            setEditItem(x);
                          }}
                        >
                          <td>{x.name}</td>
                          <td>{x.link}</td>
                          <td>{convertDate(x.createDate)}</td>
                          <td>
                            {x.updateDate ? convertDate(x.updateDate) : ""}
                          </td>
                          <td>
                            <button
                              className="btn btn-danger btn-sm"
                              role="button"
                              disabled={isActionRunning}
                              onClick={async (e) => {
                                e.stopPropagation(); // Prevent the row click event

                                await DeleteItem(x.id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>No data found.</>
          )}
        </>
      )}
    </>
  );
};
export default SocialMedia;
