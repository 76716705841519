import React, { useState, useEffect } from "react";
import {
  AddAutomationTask,
  DeleteAutomationTask,
  GetAllAutomationTasks,
  UpdateAutomationTask,
} from "../../services/apiService";
import { convertDate } from "../../Converts/DateTimeConverter";
import ParentsList from "../../components/ParentsList/ParentsList";

import { TimePicker } from "antd";
import dayjs from "dayjs";

import { DatePicker, Space } from "antd";

const Automation = ({ projectParents }) => {
  const [tasks, setTasks] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [isEditMode, setIsEditMode] = useState(null);
  const [typeOptions, setTypeOptions] = useState([
    "Publish",
    "MainSelection",
    "MainCarusel",
    "MainCarusel3D",
  ]);
  const [automationFrequency, setAutomationFrequency] = useState([
    "Once",
    "Monthly",
    "Yearly",
  ]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedIds, setSelectedIds] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [automationName, setAutomationName] = useState("demo");
  const [selectedDateTimeObject, setSelectedDateTimeObject] = useState({});

  function onPageSelect(selectedPage) {
    if (selectedPage) {
      setSelectedIds([selectedPage]);
    }
  }

  const onDateChange = (date, dateString) => {
    const tmpDateTime = selectedDateTimeObject;
    tmpDateTime.day = date.date();
    tmpDateTime.month = date.month() + 1;
    tmpDateTime.year = date.year();
    setSelectedDateTimeObject(tmpDateTime);
  };
  function onTimeChange(selectedTime) {
    const tmpDateTime = selectedDateTimeObject;
    tmpDateTime.minute = selectedTime.minute();
    tmpDateTime.hour = selectedTime.hour();
    setSelectedDateTimeObject(tmpDateTime);
  }
  async function addUpdateTaskAsync() {
    const runDate = new Date(
      selectedDateTimeObject?.year,
      selectedDateTimeObject?.month,
      selectedDateTimeObject?.day,
      selectedDateTimeObject?.hour,
      selectedDateTimeObject?.minute
    ); // JavaScript Date uses 0-based month

    const tmpTask = {
      automationId: selectedAutomation
        ? selectedAutomation.automationId
        : undefined,
      runDay: selectedDateTimeObject?.day,
      runMonth: selectedDateTimeObject?.month,
      runYear: selectedDateTimeObject?.year,
      runHour: selectedDateTimeObject?.hour,
      runMinute: selectedDateTimeObject?.minute,
      name: automationName,
      frequency: selectedFrequency,
      runDateTime: runDate.toISOString(),
      automationRunType: selectedType,
      automationEntriesIds: selectedIds.map((x) => ({
        PageId: x.levelId,
      })),
    };
    console.log(tmpTask);
    if (tmpTask.automationId) {
      //update
      const result = await UpdateAutomationTask(tmpTask);
      if (result) {
        const tmpTasks =
          tasks && tasks.length > 0
            ? tasks.filter((x) => x.automationId != tmpTask.automationId)
            : [];

        tmpTasks.push(tmpTask);
        setTasks([...tmpTasks]);
      }
    } else {
      const result = await AddAutomationTask(tmpTask);
      if (result) {
        const tmpTasks = tasks;
        tmpTasks.push(result);
        setTasks([...tmpTasks]);
      }
    }
  }
  async function deleteTaskAsync() {
    if (selectedAutomation) {
      const result = await DeleteAutomationTask([
        selectedAutomation.automationId,
      ]);
      if (result) {
        const tmpTasks =
          tasks && tasks.length > 0
            ? tasks.filter(
                (x) => x.automationId != selectedAutomation.automationId
              )
            : [];

        setTasks([...tmpTasks]);
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoadingPage) {
          setIsLoadingPage(false);
          const response = await GetAllAutomationTasks();
          setTasks(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoadingPage(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="justify-content-between d-flex">
        <div>
          <a
            type="button"
            rel="tooltip"
            className="btn btn-primary btn-sm me-2"
            onClick={(e) => setSelectedAutomation({})}
          >
            New automation <span className="small"></span>
          </a>
        </div>
      </div>
      {selectedAutomation && (
        <div className="justify-content-between d-flex">
          <div>
            <button
              role="button"
              rel="tooltip"
              className="btn btn-primary btn-sm me-2"
              onClick={async (e) => {
                await deleteTaskAsync();
              }}
              //   disabled="@isTaskRun"
            >
              Delete <span className="small"></span>
            </button>
          </div>
        </div>
      )}

      <div className="col-12 back-white border-r-3 card p-3 mt-3 mb-6">
        {isLoadingPage ? (
          <>loading..</>
        ) : (
          <>
            {tasks && tasks.length > 0 ? (
              <>
                <div className="table-responsive">
                  <table className="table align-items-center mb-0 table-hover">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Name
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Run type
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          Status
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          Frequency
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          IsActive
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          Create
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          Next Run
                        </th>
                        <th className="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasks.map((task) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={(e) => {
                              setSelectedAutomation(
                                selectedAutomation
                                  ? selectedAutomation.automationId ==
                                    task.automationId
                                    ? null
                                    : task
                                  : task
                              );
                            }}
                          >
                            <td>{task.name}</td>
                            <td>{task.automationRunType}</td>
                            <td>{task.status}</td>
                            <td>{task.frequency}</td>
                            <td>{task.isActive}</td>
                            <td>{convertDate(task.createDate)}</td>
                            <td>{convertDate(task.runDateTime)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="pager-container">
                    {/* <SfPager @ref="@Page" PageSize=@TakeValue NumericItemsCount=10 TotalItemsCount=@data.Count() ItemClick="HandleNumericClick">
                    </SfPager> */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <span>No data were found</span>
              </>
            )}
          </>
        )}
      </div>

      {selectedAutomation && (
        <div className="row col-12">
          <div className="col-6">
            <label className="font-weight-bold me-3">Select Type</label>
            <div className="flex">
              {typeOptions && typeOptions.length > 0 && (
                <>
                  {typeOptions.map((typeOption) => {
                    return (
                      <div
                        class={`badge ${
                          selectedType == typeOption
                            ? "text-bg-dark"
                            : "text-bg-primary"
                        } text-white text-center fw-bold m-2 aclickable-dark`}
                        onClick={(e) => {
                          setSelectedType(typeOption);
                        }}
                      >
                        <div className="card-body text-center fw-bold">
                          {typeOption}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <hr />
            <label className="font-weight-bold me-3">Selected pages</label>
            {selectedIds && selectedIds.length > 0 && (
              <div className="row">
                <div className="flex">
                  {selectedIds.map((page) => {
                    return (
                      <span
                        className="badge text-bg-primary text-white text-center fw-bold m-2 aclickable-dark"
                        onClick={(e) => {
                          const filteredList = selectedIds.filter(
                            (x) => x.levelId !== page.levelId
                          );
                          setSelectedIds([...filteredList]);
                        }}
                      >
                        {page.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
            <hr />
            <label className="font-weight-bold me-3">
              Select Date and time
            </label>
            <div>
              <DatePicker onChange={onDateChange} />
              <TimePicker format={"HH:mm"} onChange={onTimeChange} />
            </div>
            <hr />

            <label className="font-weight-bold me-3">Select Frequency</label>
            <div className="flex">
              {automationFrequency && automationFrequency.length > 0 && (
                <>
                  {automationFrequency.map((freq) => {
                    return (
                      <div
                        class={`badge ${
                          selectedFrequency == freq
                            ? "text-bg-datk"
                            : "text-bg-primary"
                        } text-white text-center fw-bold m-2 aclickable-dark`}
                        onClick={(e) => {
                          setSelectedFrequency(freq);
                        }}
                      >
                        {freq}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <div className="col-12">
              <div className="locationComponent">
                <ParentsList
                  parents={projectParents}
                  onSelected={onPageSelect}
                />
              </div>
            </div>
          </div>

          <div className="justify-content-between d-flex">
            <div>
              <button
                role="button"
                rel="tooltip"
                className="btn btn-primary btn-sm me-2"
                onClick={async (e) => {
                  await addUpdateTaskAsync();
                }}
                // disabled="@(isTaskRun || selectedIds?.Any() == false || string.IsNullOrEmpty(selectedType))"
              >
                Add <span className="small"></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Automation;
