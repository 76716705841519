import { Select } from "antd";
const ComboBox = ({
  dataSource,
  onChange,
  defaultValue,
  placeholder,
  className,
  value,
}) => {
  return (
    <>
      <Select
        className={className}
        showSearch
        placeholder={placeholder}
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        optionFilterProp="children"
        defaultValue={defaultValue}
        style={{ width: 120 }}
        onChange={onChange}
        options={dataSource}
        value={value ? value : undefined}
      />
    </>
  );
};
export default ComboBox;
