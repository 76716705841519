import AppSettings from "../../config/Config";
import "./Footer.module.css";

const Footer = (props) => {
  return (
    <>
      <footer className="footer py-5 bg-gradient-secondary position-relative overflow-hidden">
        <img
          src="../assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          className="position-absolute start-0 top-0 w-100 opacity-1"
          loading="lazy"
        />
        <div className="container position-relative">
          <div className="row justify-content-center mb-3">
            <img
              src="assets/images/logos/logo_white.png"
              className="img-logo rounded-max mb-3"
            />
          </div>
          <div className="row">
            <div className="col-lg-4 me-auto my-auto text-lg-start text-center">
              <h6 className="text-white font-weight-bold text-uppercase mb-2">
                {AppSettings.ApplicationName}
              </h6>
              <p className="text-white">
                Copyright © {new Date().getFullYear()}{" "}
                {AppSettings.ApplicationName} by{" "}
                <a
                  className="text-white"
                  href="https://gn-projects.com/"
                  target="_blank"
                >
                  Gn-Projects
                </a>
                .
              </p>
            </div>
            <div className="col-lg-2 col-sm-4 col-12 ms-md-auto tex t-lg-start text-center mb-sm-0 mb-4">
              <div>
                <h6 className="text-white text-sm mb-1">Company</h6>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 col-12 text-lg-start text-center mb-sm-0 mb-4">
              <div>
                <h6 className="text-white text-sm mb-1">Pages</h6>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 col-12 text-lg-start text-center mb-sm-0 mb-4">
              <div>
                <h6 className="text-white text-sm mb-1">Privacy</h6>
                <ul className="flex-column ms-lg-n3 nav">
                  <li className="nav-item">
                    <a
                      className="nav-link text-white opacity-8 text-sm pb-0"
                      href="@AppSettings.Value.OmaDotEndpoint/cookies-policy"
                      target="_blank"
                    >
                      Cookies Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white opacity-8 text-sm pb-0"
                      href="@AppSettings.Value.OmaDotEndpoint/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white opacity-8 text-sm pb-0"
                      href="@AppSettings.Value.OmaDotEndpoint/terms-and-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white opacity-8 text-sm pb-0 text-nowrap"
                      href="@AppSettings.Value.OmaDotEndpoint/end-user-license-agreement"
                      target="_blank"
                    >
                      End User License Agreement
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
