import { Modal as BSModal } from "react-bootstrap";

const Modal = ({
  title,
  onHide,
  show = true,
  FooterButtons,
  hasCloseButton = true,
  children,
  fullscreen = false,
}) => {
  return (
    <>
      <BSModal
        show={show}
        fullscreen={fullscreen}
        onHide={onHide}
        className={fullscreen ? "modal-fullscreen-custom" : ""}
      >
        <BSModal.Header>
          <BSModal.Title>{title}</BSModal.Title>{" "}
          {/* <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </BSModal.Header>
        <BSModal.Body>{children}</BSModal.Body>
        {FooterButtons && (
          <BSModal.Footer>
            <FooterButtons />
          </BSModal.Footer>
        )}
      </BSModal>
    </>
  );
};
export default Modal;
