import React, { useState, useEffect } from "react";

import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { DatePicker, Space } from "antd";
// import "antd/dist/antd.css";
import moment from "moment";
import { GetRangeViews, GetTotalViews } from "../../services/apiService";
import { v4 as uuidv4 } from "uuid";
import Input from "../../components/Basic/Input";

// Register the required components for Chart.js
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ScrollableTable = (tmpData) => {
  const initialData =
    tmpData && tmpData.data && tmpData.data.length > 0 ? [...tmpData.data] : [];
  const [tableData, setTableData] = useState([...initialData]);
  const [amountSearch, setAmountSearch] = useState("");
  const [dataSearch, setDataSearch] = useState("");

  const handleAmountSearch = (e) => {
    setAmountSearch(e.target.value);
  };

  const handleDataSearch = (e) => {
    setDataSearch(e.target.value);
  };

  const filteredData = initialData.filter(
    (item) =>
      (amountSearch === "" || item.amount == amountSearch) &&
      (dataSearch === "" ||
        item.data.toLowerCase().toString().includes(dataSearch.toLowerCase()))
  );
  return (
    <div>
      <div>
        <Input
          type="text"
          placeholder={"Search by views"}
          value={amountSearch}
          onChange={handleAmountSearch}
        />

        <Input
          type="text"
          placeholder="Search by name"
          value={dataSearch}
          onChange={handleDataSearch}
        />
      </div>
      <div style={{ height: "200px", overflowY: "scroll", marginTop: "20px" }}>
        <div className="table-responsive">
          <table className="table align-items-center mb-0 table-hover">
            <thead>
              <tr>
                <th>Views</th>
                <th>Page</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .sort((a, b) => b.amount - a.amount)
                .map((item) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>{item.amount}</td>
                      <td>{item.data}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Views = (props) => {
  const [isPageInited, setIsPageInited] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isLoadingTotal, setIsLoadingTotal] = useState(true);
  const [startDate, setStartDate] = useState(moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [views, setViews] = useState(null);
  const [totalViews, setTotalViews] = useState(null);
  const [data, setData] = useState(null);
  const [operationSystemData, setOperationSystemData] = useState(null);
  const [countriesData, setCountriesData] = useState(null);
  const [devicesData, setDevicesData] = useState(null);
  const [urlsData, setUrlsData] = useState(null);

  useEffect(() => {
    // applyChart();
    const fetchTotalData = async () => {
      try {
        if (!isPageInited) {
          const response = await GetTotalViews();
          setTotalViews(response);
        }
      } catch (error) {
        console.error("Error fetching total data:", error);
      }
      setIsLoadingTotal(false);
    };
    const fetchData = async () => {
      try {
        if (!isPageInited) {
          await initPageBasicData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoadingPage(false);
    };
    fetchData();

    fetchTotalData();
  }, []);
  const initPageBasicData = async () => {
    const response = await GetRangeViews(
      startDate.format("DD.MM.YYYY"),
      endDate.format("DD.MM.YYYY")
    );
    setViews(response);
    setData(generateDataset(response.currentWeek));
    setOperationSystemData(generateDatasetElse(response.operatingSystem));
    setCountriesData(generateDatasetElse(response.visitorCountry));
    setDevicesData(generateDatasetElse(response.deviceType));
    setUrlsData(response.pageURL);
  };
  const generateDataset = (tmpData) => {
    const labels =
      tmpData && tmpData.length > 0
        ? tmpData.map((x) => moment(x.date).format("DD.MM.YYYY"))
        : [];
    const chartData =
      tmpData && tmpData.length > 0 ? tmpData.map((x) => x.amount) : [];

    return {
      labels,
      datasets: [
        {
          label: "Views",
          data: chartData,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          fill: true,
          pointRadius: 5,
          pointHoverRadius: 7,
          pointBorderWidth: 2,
          pointBackgroundColor: "rgba(75, 192, 192, 1)",
          tension: 0.4,
        },
      ],
    };
  };
  const generateDatasetElse = (tmpData) => {
    const labels =
      tmpData && tmpData.length > 0 ? tmpData.map((x) => x.data) : [];
    const chartData =
      tmpData && tmpData.length > 0 ? tmpData.map((x) => x.amount) : [];

    return {
      labels,
      datasets: [
        {
          label: "Views",
          data: chartData,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          fill: true,
          pointRadius: 5,
          pointHoverRadius: 7,
          pointBorderWidth: 2,
          pointBackgroundColor: "rgba(75, 192, 192, 1)",
          tension: 0.4,
        },
      ],
    };
  };
  function onEndDateChange(date, dateString) {
    setEndDate(date);
  }

  function onStartDateChange(date, dateString) {
    setStartDate(date);
  }

  async function applyChart() {
    if (!startDate || !endDate) return;
    await initPageBasicData();
  }

  return (
    <>
      <div>
        <div>
          <DatePicker value={startDate} onChange={onStartDateChange} />
          <DatePicker value={endDate} onChange={onEndDateChange} />

          <button
            className="btn btn-sm btn-primary"
            onClick={(e) => applyChart()}
          >
            Set
          </button>
        </div>
        {data && data.labels && data.labels.length > 0 && (
          <>
            <Line
              data={data}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: `Last 7 days view ${
                      startDate && endDate
                        ? `(${startDate.format(
                            "DD.MM.YYYY"
                          )} - ${endDate.format("DD.MM.YYYY")})`
                        : ""
                    }`,
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Date",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Value",
                    },
                  },
                },
              }}
            />
            <div className="row mb-3">
              <div className="col-md-6">
                <Pie
                  data={operationSystemData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Operation systems",
                      },
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <Pie
                  data={countriesData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Countries",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <ScrollableTable data={urlsData} />
              </div>
              <div className="col-md-6">
                <Bar
                  data={devicesData}
                  options={{
                    indexAxis: "y",
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Horizontal Bar Chart Example",
                      },
                      tooltip: {
                        mode: "index",
                        intersect: false,
                      },
                    },
                    scales: {
                      x: {
                        beginAtZero: true,
                      },
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoadingTotal && (
        <>
          {totalViews && totalViews.views.length > 0 && (
            <div
              style={{ height: "400px", overflowY: "scroll" }}
              className="mt-4"
            >
              {totalViews.startDate && totalViews.endDate && (
                <p className="fw-bold">
                  {moment(totalViews.startDate).format("DD.MM.YYYY")} -{" "}
                  {moment(totalViews.endDate).format("DD.MM.YYYY")}
                </p>
              )}

              <div className="table-responsive">
                <ScrollableTable data={totalViews.views} />

                <div className="pager-container">
                  {/* <SfPager @ref="@Page" PageSize=@TakeValue NumericItemsCount=10 TotalItemsCount=@data.Count() ItemClick="HandleNumericClick">
                    </SfPager> */}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Views;
